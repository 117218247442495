import React from 'react';
import { Col, Container, Row, Placeholder, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './../../CommonStyling.css';
import { useIsMobile } from '../common/Utils';

const Jumbotron = ({ event, loading = false }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleClick = () => {
    navigate(`/${event.section}/${event.id}`);
  }

  const dynamicCard = event.body && !isMobile;

  return (
    <Container className={`p-4 p-md-5 mt-3 mb-4 rounded text-body-emphasis bg-body-secondary position-relative`}>
      <Row className={isMobile && 'mx-1'}>
        <Col lg={11} className="px-0">
          {loading ? (
            <Placeholder as={'h1'} animation="glow">
              <Placeholder xs={12} />
              <Placeholder xs={12} />
            </Placeholder>
          ) : (
            <h1 className="display-4 fst-italic">{event.title}</h1>
          )}
          {loading ? (
            <Placeholder as={'p'} animation="glow">
              <Placeholder xs={12} />
              <Placeholder xs={12} />
              <Placeholder xs={12} />
            </Placeholder>
          ) : (
            <p className="lead my-3">{event.description}</p>
          )}
          {loading && <Placeholder as={'p'} animation="glow"><Placeholder xs={12} /></Placeholder>}
          {event.body && <p className="lead mb-0">
            <Button className="stretched-link text-body-emphasis fw-bold" onClick={() => { !dynamicCard && handleClick() }}>
              Read the Report
            </Button>
          </p>}
        </Col>
      </Row>
      {dynamicCard && <div className='overlay transition' onClick={handleClick} />}
    </Container>
  );
};

export default Jumbotron;