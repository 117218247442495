import React from 'react';
import { Container } from 'react-bootstrap';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import EventJumbotron from '../components/home/Jumbotron';
import Loading from '../components/home/Loading';
import { collection, orderBy, where, query, limit, getDocs, startAfter } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import CardList from '../components/common/CardList';
import { useInfiniteQuery } from 'react-query';
import ScrollTrigger from '../components/common/ScrollTrigger';

const PAGE_SIZE = 10;

function Home() {
  let { id } = useParams(); //this is SECTION id
  const firestore = useFirestore();
  
  const reportsCollection = collection(firestore, 'reports');

  const fetchReportsBatch = async ({ pageParam }) => {
    //get list of constraints based on context
    const queryConstraints = [];
    id && queryConstraints.push(where('section', '==', id));    //filter by section
    queryConstraints.push(orderBy('date', 'desc'));          //most recent -> oldest
    pageParam && queryConstraints.push(startAfter(pageParam));  //pagination - page
    queryConstraints.push(limit(PAGE_SIZE));                    //pagination - page size

    const reportsQuery = query(reportsCollection, ...queryConstraints);
    const reportSnapshot = await getDocs(reportsQuery);
    
    //construct data object from document id and members
    const data = reportSnapshot.docs.map((doc) => {return {id: doc.id, ...doc.data()}})
    
    //needs to return last snapshot document from this page for getting position of the next call
    return {data, lastItem: reportSnapshot.docs[reportSnapshot.docs.length - 1]};
  }

  const { 
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['reports'],
    queryFn: fetchReportsBatch,
    getNextPageParam: (lastPage, pages) => lastPage.lastItem
  });
  
  if (status === 'loading') {
    return <Loading />;
  }
  
  //if data is available flatten just the actual pages
  const flatData = data.pages.flatMap((page) => {
    return page.data;
  });

  const showEndPlaceholder = () => {
    return isFetchingNextPage && data.pages[data.pages.length - 1].data.length === PAGE_SIZE;
  }

  const jumboData = (flatData.length > 0 ? flatData[0] : { title: 'No Reports', description: 'There are no reports at this time.' })
  return (
    <Container>
      <EventJumbotron event={jumboData} />
      <CardList data={flatData.slice(1)} showEndPlaceholder={showEndPlaceholder()}/>
      {hasNextPage && <ScrollTrigger onIsVisible={() => fetchNextPage()}/>}
    </Container>
  );
}

export default Home;