import { useState } from "react";
import UpvoteDownvote from "./UpvoteDownvote";
import { Dropdown } from "react-bootstrap";
import GoogleAuthButton from "../auth/GoogleAuthButton";
import AppleAuthButton from "../auth/AppleAuthButton";

const FeedbackControlsLoggedOut = ({vote, setVote}) => {
    const [showLogInOptions, setShowLogInOptions] = useState(false);
    
    const onUpvote = () => {
        if(vote === 1) return;
        !showLogInOptions && setShowLogInOptions(true);
        setVote(1);
    }

    const onDownvote = () => {
        if(vote === -1) return;
        !showLogInOptions && setShowLogInOptions(true);
        setVote(-1);
    }

    return (
        <div className="p-4 mb-3 bg-body-tertiary rounded">
            <h5 className="text-white">How did we do?</h5>
        {/* <div style={{position: "relative"}}> */}
            <Dropdown show={showLogInOptions === true}>
                <UpvoteDownvote vote={vote} onUpvote={onUpvote} onDownvote={onDownvote}/>

                <Dropdown.Menu>
                    <Dropdown.Header className="text-light" style={{fontSize: "16px"}}>Please sign in to vote:</Dropdown.Header>
                    <GoogleAuthButton/>
                    <AppleAuthButton/>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default FeedbackControlsLoggedOut;