import { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useFunctions, useFirestore, useFirestoreCollectionData } from 'reactfire';
import { doc, collection, query, orderBy } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';

function GenerateWorkstream() {
  const functions = useFunctions();
  const firestore = useFirestore();
  const templatesCollection = collection(firestore, 'templates');
  const templatesQuery = query(templatesCollection, orderBy('priority', 'asc'));
  const { status: templatesStatus, data: templates } = useFirestoreCollectionData(templatesQuery, {
    idField: 'id',
  });

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const generateWorkstream = httpsCallable(functions, 'generateWorkstream');
  const createWorkstream = httpsCallable(functions, 'createWorkstream');

  const [generating, setGenerating] = useState(false);
  const [goal, setGoal] = useState('');
  const [config, setConfig] = useState("");
  const [additionalContext, setAdditionalContext] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // set default config
  useEffect(() => {
    if (templates) {
      setConfig(templates[0].content);
    }
  }
    , [templates]);

  async function handleCreateWorkstream() {
    setGenerating(true);
    const title = config.split('\n')[0];
    let result;
    try {
      result = await createWorkstream({ goal: title, content: config });
    } catch (e) {
      console.error(e);
    }
    setShow(false);
    setGenerating(false);
    setGoal('');
    navigate(`/studio/${result.data}?view=config`);
  }

  async function handleGenerateWorkstream() {
    setGenerating(true);
    let result;
    try {
      result = await generateWorkstream({ name: goal, goal: goal });
    } catch (e) {
      console.error(e);
    }
    setShow(false);
    setGenerating(false);
    setGoal('');
    navigate(`/studio/${result.data}?view=config`);
  }

  return (
    <>
      <Button variant="outline-secondary" size='sm' className="ms-auto" onClick={handleShow}>
        {generating
          ? (<Spinner animation="border" size="sm" role="status" aria-hidden="true" />)
          : "🏆"
        }
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create a Workstream</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>🏆 Goal</Form.Label>
              <Form.Select
                size="md"
                onChange={(e) => setConfig(e.target.value)}
              >
                {templates
                  ?
                  templates.map((template, index) => (
                    <option key={index} value={template.content}>{template.title}</option>
                  ))
                  : <option disabled>Loading...</option>}
                <option value={'generate'}>{"✨ Custom"} </option>
              </Form.Select>
            </Form.Group>
            {config === 'generate' &&
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Control
                    type="text"
                    placeholder="Write a report about..."
                    autoFocus
                    value={goal}
                    onChange={(e) => setGoal(e.target.value)}
                  />
                </Form.Group>
              }
            {/* <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>📚 Additional Context</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={additionalContext}
                onChange={(e) => setAdditionalContext(e.target.value)}
              />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary" d
            isabled={generating}
            onClick={(config === 'generate' ? handleGenerateWorkstream : handleCreateWorkstream)}
          >
            {(generating
              ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
              : (config === 'generate' ? "Generate" : "Create")
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GenerateWorkstream;