import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { createRoot } from 'react-dom/client';
import { FirebaseAppProvider } from 'reactfire';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Preload } from './components/common/ImagePreloader';
import GoogleLogoSVG from './assets/Google__G__Logo.svg';
import AppleLogoSVG from './assets/Apple_logo_white.svg';

const firebaseConfig = {
  apiKey: "AIzaSyCByQ4NECNYkJk0Qmn-rLAhXEI5r0tIjuU",
  authDomain: "gptimes-56765.firebaseapp.com",
  projectId: "gptimes-56765",
  storageBucket: "gptimes-56765.appspot.com",
  messagingSenderId: "746327391525",
  appId: "1:746327391525:web:447004aa6a71cc36f4eecc",
  measurementId: "G-QQ3DJLKD6Y"
};

const container = document.getElementById('root');
const root = createRoot(container); 

const queryClient = new QueryClient();

Preload(GoogleLogoSVG);
Preload(AppleLogoSVG);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <App />
        </FirebaseAppProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

