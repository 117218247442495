import React from 'react';
import { Card, Col, Badge, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './ReportCard.css';
import './../../CommonStyling.css';
import { useIsMobile } from '../common/Utils';
import DateDisplay from '../common/DateDisplay';

const ReportCard = ({ report }) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const handleClick = () => {
        navigate(`/${report.section}/${report.id !== undefined ? report.id : report.NO_ID_FIELD}`); //graceful handling in case NO_ID_FIELD bug
    }

    const formatSection = (section) => {
        if (typeof section === 'string') {
            if (section === 'us') return 'U.S.';
            section = section.replace(/-/g, ' ');
            section = section.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            return section;
        }
        return section;
    }

    const dynamicCard = report.body && !isMobile;
    let previewString = (report.body ? report.body.substring(0, 200) + '...' : "Breaking news generating...");
    previewString = (report.description ? report.description : previewString)
    return (
        <Col md={6}>
            <Card className={`border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-300 position-relative ${dynamicCard && 'highlight-on-hover transition use-pointer'}`}>
                <Col className="p-4 d-flex flex-column position-static mh-100">
                    <div className="d-flex flex-column overflow-hidden overflow-fade" style={{ height: '250px' }}>
                        <strong className="d-inline-block mb-2 text-secondary flex-shrink-0">{formatSection(report.section)}</strong>
                        <h3 className="mb-2 text-break text-white flex-shrink-0">{report.title}</h3>
                        {/* <DateDisplay dateString={report.dateString} updated={report.updated} /> */}
                        <p className="card-text mb-auto flex-shrink-1">
                            {previewString}
                        </p>
                    </div>
                    {report.body && <Card.Link className="stretched-link transition" onClick={() => { !dynamicCard && handleClick() }}>Read More</Card.Link>}
                </Col>
                {dynamicCard && <div className='overlay transition' onClick={handleClick} />}
                {report.image &&
                    <Col xs={'auto'} className="d-none d-lg-block">
                        <Card.Img src={report.image} alt="Thumbnail" height="100%" />
                    </Col>
                }
            </Card>
        </Col>
    );
}

export default ReportCard;