import React from 'react';
import { Row } from 'react-bootstrap';
import ReportCard from '../home/ReportCard';
import PlaceholderCard from './PlaceholderCard';


const formatReportDateString = (report) => {
    const date = new Date(report.date.seconds * 1000)
    return date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })
}

const CardList = ({data, showEndPlaceholder = false}) => {
    return (
        <Row>
            {data.map((report, i) => {
                report.dateString = formatReportDateString(report);
                return <ReportCard key={i} report={report} vertical />
            })}
            {showEndPlaceholder && <PlaceholderCard/>}
        </Row>
    );
}

export default CardList;