import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Nav } from 'react-bootstrap';
import { useAuth } from 'reactfire';
import SideMenu, { SideMenuListItem } from '../common/SideMenu';
import { ChevronRight, List, Person, PersonLinesFill } from 'react-bootstrap-icons';
import { useIsMobile } from '../common/Utils';

const LoggedInAuthHeaderComponent = ({user}) => {
    const [showAccountMenu, setShowAccountMenu] = useState(false);

    const isMobile = useIsMobile();
    const auth = useAuth();
    const navigate = useNavigate();

    const signOut = async (auth) => {
        auth.signOut().then(() => {
            //a bit annoying but we need this to avoid a bug in reactfire: 
            //https://stackoverflow.com/questions/64197635/missing-permissions-error-message-right-after-login
            window.location.reload();
        })
    };

    const AccountMenu = showAccountMenu && <SideMenu onClose={(e) => {e.stopPropagation(); setShowAccountMenu(false)}} position='right'>
        <h2 className='text-start text-white mb-3'>
            Welcome, 
            <div>
                {user.displayName.split(' ')[0]}
            </div>
        </h2>
        <h6 className='text-start'>
            {user.email}
        </h6>
        <SideMenuListItem onClick={(e) => {e.stopPropagation(); navigate('/privacy')}}>Privacy</SideMenuListItem>
        <SideMenuListItem onClick={(e) => {e.stopPropagation(); signOut(auth)}}>Sign out</SideMenuListItem>
    </SideMenu>

    if(isMobile) {
        return (
            <Nav.Link onClick={() => !showAccountMenu && setShowAccountMenu(true)}>
                <Person/>
                {AccountMenu}
            </Nav.Link>
        )
    }

    return (
        <Button variant='outline-secondary d-flex align-items-center justify-content-between' style={{height:"42px", minWidth: "42px",maxWidth: "85%"}} onClick={() => setShowAccountMenu(true)}>
            <PersonLinesFill/>
            {AccountMenu}
        </Button>
    );
}

export default LoggedInAuthHeaderComponent;