import React, { useState } from 'react';
import { Container, ListGroup, Button, ButtonGroup, Spinner, Stack, Card } from 'react-bootstrap';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import {useNavigate} from 'react-router-dom';
import GenerateWorkstream from './GenerateWorkstream';

const Workstreams = ({ workstreams }) => {
    // display a list of workstreams. The list should take up the full height of the parent container
    const functions = useFunctions();
    const navigate = useNavigate();
    const createWorkstream = httpsCallable(functions, 'create_user_workstream');

    const [loading, setLoading] = useState(false);

    async function handleButtonClick(firstNumber, secondNumber, operator) {
        setLoading(true);
        await createWorkstream({ name: "New Workstream" });
        setLoading(false);
    }

    async function handleNavigate(workstream_id) {
        navigate(`/studio/${workstream_id}`);
    }

    return (
        <Container className="p-4 mb-3 bg-body-tertiary rounded" style={{height:"100%"}}>
            <Stack className="mb-3" direction='horizontal'>
                <h2>Workstreams</h2>
                <ButtonGroup className="ms-auto">
                    {/* <Button variant="secondary" size='sm' onClick={handleButtonClick}>
                        {loading
                            ? (<Spinner animation="border" size="sm" role="status" aria-hidden="true" />)
                            : "➕"
                        }
                    </Button > */}
                    <GenerateWorkstream />
                </ButtonGroup>
            </Stack>
            <ListGroup>
                {workstreams.map((workstream) => (
                    <ListGroup.Item 
                        action
                        key={workstream.id}
                        active={workstream.id === window.location.pathname.split('/').pop()}
                        onClick={() => {handleNavigate(workstream.id);}}>
                        {workstream.name}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Container>
    );
};

export default Workstreams;
