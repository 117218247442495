import React from 'react';
import { Button, Container, Card } from 'react-bootstrap';

function Upgrade() {
    return (
        <Container style={{ height: "calc(100vh - 250px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Card style={{ width: "300px"}}>
                <Card.Img variant="top" src="gradient-thin.svg" />
                <Card.Body >
                    <Card.Title>Hire a team of AI Journalists</Card.Title>
                    <Card.Text>
                    Join the waitlist to try GPTimes Studio. We'll let you know when you're in.
                    </Card.Text>
                    <Button variant="primary">Join the Waitlist</Button>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">By joining the waitlist you agree to our <a href='/privacy'>privacy policy</a>.</small>
                </Card.Footer>
            </Card>
        </Container>
    );
}

export default Upgrade;
