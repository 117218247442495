import React, { useState, useEffect, useRef } from 'react';
import { Container, Stack, Form, InputGroup, Button, Card, Badge, Spinner } from "react-bootstrap";
import ChatCard from './ChatCard';
import { useAuth, useFirestore, useFunctions, useFirestoreDocData, useFirestoreCollectionData } from 'reactfire';
import { doc, collection, query, orderBy } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';


function Chat({workstream_id, workstream, messages, workstreamLaunching, setWorkstreamLaunching}) {
    // get the user id from the auth object
    const auth = useAuth();
    const firestore = useFirestore();
    const functions = useFunctions();
    const navigate = useNavigate();
    const user_id = auth.currentUser.uid;

    const [inputValue, setInputValue] = useState('');
    const [sending, setSending] = useState(false);
    const chatContainerRef = useRef(null);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSendMessage = async () => {
        if (inputValue.trim() !== '') {
            setSending(true);
            console.log('sending message:', inputValue);
            const sendMessage = httpsCallable(functions, 'add_message');
            await sendMessage({ workstream_id, content: inputValue });
            setSending(false);
            setInputValue('');
        }
    };

    const handleViewChange = () => {
        navigate(`/studio/${workstream_id}?view=config`);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const chatBusy = sending || workstreamLaunching;

    useEffect(() => {
        // Scroll to the bottom of the chat container
        const scrollHeight = chatContainerRef.current.scrollHeight;
        chatContainerRef.current.scrollTo({
            top: scrollHeight,
            behavior: 'smooth' // smooth scroll
          });
    }, [messages]);

    return (
        <Container style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: 'relative',
        }}>

            <Container style={{
                height: "100%",
                overflow: "auto",
            }} ref={chatContainerRef}>
                <Stack direction='vertical' gap={2} style={{maxHeight:"100px"}}>
                    {messages.map((message, index) => (
                        <ChatCard message={message} sent={index % 2 === 0} key={index}/>
                    ))}
                    <ChatCard hidden message={{content: 'You shouldnt see this', created: new Date()}} sent={true}/>
                </Stack>
            </Container>
            <Card style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
            }}>
                <InputGroup>
                    <InputGroup.Text>
                        🤖 <Badge bg="secondary">{(workstream.agents ? workstream.agents.length : 0)}</Badge>
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Write a report about..."
                        value={inputValue}
                        disabled={chatBusy}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    <Button variant='outline-primary' onClick={handleSendMessage}>
                        {(chatBusy ? <Spinner className='m-2' size="sm" animation="border" role="status" aria-hidden="true" /> : "⬆️")}
                    </Button>
                </InputGroup>
            </Card>
            <Card style={{
                position: 'absolute',
                top: '0',
                right: '0',
            }}>
                <Button variant='outline' onClick={handleViewChange}>
                    ⚙️
                </Button>
            </Card>
        </Container>
    );
}

export default Chat;
