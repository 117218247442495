import React from 'react';
import { Button, Container, Carousel, Card } from 'react-bootstrap';
import { ReactComponent as Gradient } from '../assets/gradient.svg';
import LoggedOutAuthHeaderComponent from '../components/auth/LoggedOutAuthHeaderComponent';

function Login() {
    return (
        <Container style={{ height: "calc(100vh - 250px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Card style={{ width: "300px"}}>
                <Card.Img variant="top" src="gradient-thin.svg" />
                <Card.Body >
                    <Card.Title>Accounts get you more... </Card.Title>
                    <Card.Text>
                    💬 Contribute to Reports
                    <br />
                    ✉️ Subscribe to Digests
                    <br />
                    ✨ Create with AI
                    </Card.Text>
                    <LoggedOutAuthHeaderComponent />
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Login;
