import { Placeholder } from "react-bootstrap"
import ReportCard from "../home/ReportCard"

const PlaceholderCard = () => {
    const report = {
        title: <Placeholder animation='glow'>
            <Placeholder xs={10}/>
            <Placeholder xs={8}/>
            <Placeholder xs={5}/>
        </Placeholder>,
        section: <Placeholder as="strong" animation='glow'>
            <Placeholder xs={3}/>
        </Placeholder>,
        dateString: <Placeholder as="div" animation='glow'>
            <Placeholder xs={6}/>
        </Placeholder>
    }
    return (
        <ReportCard report={report} vertical />
    )
}

export default PlaceholderCard;