import {useEffect} from 'react';
import { Container, Row, Col, Button, Placeholder } from 'react-bootstrap';
import { useFirestore, useFirestoreDocData, useFirestoreCollectionData, useFirestoreDocDataOnce } from 'reactfire';
import { doc, collection, query, where, documentId } from 'firebase/firestore';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { ChevronLeft } from 'react-bootstrap-icons'
import Sources from '../components/report/Sources';
import Warning from '../components/report/Warning';
import FeedbackForm from '../components/report/FeedbackForm';
import AboutCard from '../components/common/AboutCard';
import DateDisplay from '../components/common/DateDisplay';

// This thing is still a mess 
// TODO: componentize and clean up
function Report() {
    let { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const firestore = useFirestore();
    const reportDoc = doc(firestore, 'reports', id);
    const { status, data } = useFirestoreDocDataOnce(reportDoc);
    const sourceIds = data ? data.sources : ["firebase is dumb and exploedes if you Where->in an empty array"];
    // get sources
    const sourcesCollection = collection(firestore, 'sources');
    const sourcesQuery = query(sourcesCollection, where(documentId(), 'in', sourceIds));
    const { status: sourceStatus, data: sources } = useFirestoreCollectionData(sourcesQuery, {
        idField: 'id',
    });

    if (status === 'loading' || sourceStatus === 'loading') {
        return (
            <Placeholder as="p" animation='glow'>
                <Placeholder xs={10}/>
                <Placeholder xs={8}/>
                <Placeholder xs={3}/>
                <Placeholder xs={7}/>
                <Placeholder xs={12}/>
                <Placeholder xs={5}/>
                <Placeholder xs={9}/>
            </Placeholder>
        )
    }

    const date = new Date(data.date.seconds * 1000)
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateString = date.toLocaleDateString('en-US', { timeZone: tz, weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

    var reportBody = data.body;
    console.log(sources)
    if (sources) {
        reportBody += '\n\n---\n\n'
        sources.forEach(source => {
            reportBody += `[^${source.id}]: ${source.title} [${source.publisher}](${source.url})\n`
        })
    }
    console.log(reportBody)
    return (
        <Container>
            <Row className="g-5">
                <Col md={8}>
                    {location.key !== 'default' &&
                        <Button onClick={() => navigate(-1)} variant='link text-decoration-none align-middle pt-0 px-0'>
                            <ChevronLeft size={20} className='align-text-top' /> Back
                        </Button>
                    }

                    <article className="blog-post blog-body">
                        <h2 className="display-3 mb-1 text-white blog-title">{data.title}</h2>
                        <DateDisplay dateString={dateString} updated={data.updated} />
                        <Warning message={'AI generated content can vary in quality and correctness. Rate reports to help us improve!'}/>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {reportBody}
                        </ReactMarkdown>
                    </article>

                </Col>

                <Col md={4}>
                    <div className="position-sticky" style={{ top: "2rem" }}>
                        <AboutCard />
                        <FeedbackForm reportId={reportDoc.id}/>
                        <h4 className="fst-italic">Sources</h4>
                        {sources && <Sources sources={sources}/>}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Report;