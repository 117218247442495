import { Container } from 'react-bootstrap';
import EventJumbotron from './Jumbotron';

function Loading() {
    return (
        <Container>
            <EventJumbotron event={{ title: 'Loading...', description: 'Loading...' }} loading />
        </Container>
    )
}

export default Loading;