import React from 'react';
import { Container } from 'react-bootstrap';

const Privacy = () => {
  return (
    <Container>
      <h1>GPTimes Privacy Policy</h1>
      <p><strong>Last Updated:</strong> 10 Feb. 2024</p>
      <p>Welcome to GPTimes! Your privacy is important to us. This Privacy Policy explains how we collect, use, share, and protect your personal information when you visit and interact with gptimes.ai. By using our services, you agree to the collection and use of information in accordance with this policy.</p>
      
      <h2>Information We Collect</h2>
      <p>When you visit GPTimes, we automatically collect certain information about your device and interaction with our site. This includes:</p>
      <ul>
        <li><strong>Page Views:</strong> We track which pages you view on our site.</li>
        <li><strong>Scrolls:</strong> We monitor how you scroll through our content to understand your engagement.</li>
        <li><strong>Outbound Clicks:</strong> We track when you click on links that lead you away from our site.</li>
        <li><strong>Site Search:</strong> We keep records of the searches you perform on our site.</li>
        <li><strong>Video Engagement:</strong> We monitor how you interact with videos on our site.</li>
        <li><strong>File Downloads:</strong> We track when you download files available on our site.</li>
        <li><strong>Form Interactions:</strong> We record your interactions with any forms on our site, such as contact forms or surveys.</li>
      </ul>
      
      <p>To facilitate these tracking activities, we use Google Analytics. Google Analytics helps us understand how our users engage with our website, allowing us to improve your experience over time.</p>
      <p>Additionally, when you sign up for an account with GPTimes, you provide us with your:</p>
      <ul>
        <li><strong>Name</strong></li>
        <li><strong>Email Address</strong></li>
      </ul>
      <p>This information is collected via sign-up options with Google, Apple, and Microsoft.</p>

      <h2>How We Use Your Information</h2>
      <p>The information we collect allows us to:</p>
      <ul>
        <li>Provide, maintain, and improve our website and services.</li>
        <li>Understand and analyze how you use our website to improve user experience.</li>
        <li>Communicate with you, including sending updates or offers for which you have opted in.</li>
      </ul>
      
      <h2>Sharing Your Information</h2>
      <p>We do not share your personal information with third parties except as necessary to provide our services or as required by law. This includes sharing information with Google Analytics to analyze our website traffic.</p>
      
      <h2>Your Choices</h2>
      <h3>Emails</h3>
      <p>You have the option to opt-in to receiving emails from us. If you decide at any time that you no longer wish to receive such communications, you can opt-out using the unsubscribe link in the emails.</p>
      
      <h3>Cookies</h3>
      <p>Our site uses cookies to track website activity and user interactions. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our website may become inaccessible or not function properly.</p>
      
      <h2>Security</h2>
      <p>We are committed to ensuring the security of your personal information. We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>
      
      <h2>Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our website prior to the change becoming effective and update the "Last Updated" date at the top of this Privacy Policy.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:contact@gptimes.ai">contact@gptimes.ai</a>.</p>
      
      <p>Thank you for choosing GPTimes!</p>
    </Container>
  );
};

export default Privacy;
