// wrapper for components that require authentication, if user is not authenticated, redirect to login (useNavigate)

import React from 'react';
import { useFirestoreDocData, useFirestore, useAuth } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { doc } from 'firebase/firestore';

const RequireAuth = ({ children }) => {
    const navigate = useNavigate();
    const firestore = useFirestore();
    const auth = useAuth();
    const user_id = auth.currentUser.uid;

    const userDoc = doc(firestore, 'users', user_id);
    const { status: status, data: user } = useFirestoreDocData(userDoc);

    if (status === 'loading') {
        return <h1>Checking subscription status...</h1>;
    }

    if (user.tokens <= 0) {
        navigate('/upgrade');
        return null;
    }

    return children;
}

export default RequireAuth;
