import React from 'react';
import { Container, Placeholder } from 'react-bootstrap';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import EventJumbotron from '../components/home/Jumbotron';
import { collection, orderBy, where, query, limit, getDocs, startAfter } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useSigninCheck } from 'reactfire';

function Front() {
    let { id } = useParams();
    const firestore = useFirestore();
    const digestCollection = collection(firestore, 'digests');
    const digestQuery = query(digestCollection, orderBy('created', 'desc'), limit(1));
    const { status: status, data: digest } = useFirestoreCollectionData(digestQuery, {
        idField: 'id',
    });
    const { status: authStatus, data: signInCheckResult } = useSigninCheck();


    if (status === 'loading' || authStatus === 'loading') {
        return (
            <Placeholder as="p" animation='glow'>
                <Placeholder xs={10}/>
                <Placeholder xs={8}/>
                <Placeholder xs={3}/>
                <Placeholder xs={7}/>
                <Placeholder xs={12}/>
                <Placeholder xs={5}/>
                <Placeholder xs={9}/>
            </Placeholder>
        )
    }
    
    const digestData = digest[0];
    console.log(digest)
    // const date = new Date(digestData.date.seconds * 1000)
    // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const dateString = date.toLocaleDa
    
    // Get the welcome message based on the current time
    const now = new Date();
    const hours = now.getHours();
    const greeting = hours < 12 ? 'Good Morning' : hours < 18 ? 'Good Afternoon' : 'Good Evening';
    let name = signInCheckResult.signedIn ? signInCheckResult.user.displayName.split(" ")[0] : '';
    console.log("Auth status: ", authStatus);
  
  const jumboData = { title: `${greeting} ${name}`, description: digestData.title }
  return (
    <Container>
      <EventJumbotron event={jumboData} />
      <Container>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {digestData.body}
        </ReactMarkdown>
        </Container>
    </Container>
  );
}

export default Front;