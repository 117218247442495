import AppleLogoSVG from "../../assets/Apple_logo_white.svg";
import { OAuthProvider, getRedirectResult, signInWithRedirect } from "firebase/auth";
import { useAuth } from "reactfire";
import AuthButton from "./AuthButton";

const AppleAuthButton = () => {
    const auth = useAuth();

    const signInWithApple = async () => {
       const provider = new OAuthProvider('apple.com');
       provider.addScope('name');
       provider.addScope('email');
       await signInWithRedirect(auth, provider);
       // This will trigger a full page redirect away from your app
       
       // After returning from the redirect when your app initializes you can obtain the result
       const result = await getRedirectResult(auth);
       if (result) {
         // This is the signed-in user
         const user = result.user;
         // This gives you a OAuth Access Token for the provider.
         const credential = provider.credentialFromResult(auth, result);
         const token = credential.accessToken;
       }
    }

    return <AuthButton logo={AppleLogoSVG} name="Apple" onClick={() => signInWithApple()}/>
}

export default AppleAuthButton;