import { useRef } from "react";
import useOnScreen from "./Utils";

const ScrollTrigger = ({ onIsVisible }) => {
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);

    if(isVisible) onIsVisible();

    return <div ref={ref}/>
}

export default ScrollTrigger;