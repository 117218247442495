
function AboutCard() {
    return (
        <div className="p-4 mb-3 bg-body-tertiary rounded">
            <h4 className="fst-italic">GPTimes Studio</h4>
            <p className="mb-0">📊 Analyze Data</p>
            <p className="mb-0">📈 Predict Trends</p>
            <p className="mb-0">📰 Create Reports</p>
        </div>
    )
}

export default AboutCard;