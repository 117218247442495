import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { collection } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const loadingSourceData = [
    {
        title: 'Loading...',
        publishedAt: 'Loading...',
        url: '#'
    }
]

const Sources = ({ sources }) => {

  return (
    <ListGroup variant="flush">
      {sources.map((source, index) => (
        <ListGroup.Item key={index} className="border-top">
          <a className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none" target='_blank' href={source.url}>
            {/* Replace this with your actual content */}
            {/* You can use post.title, post.date, and other properties */}
            {/*<svg className="bd-placeholder-img" width="100%" height="96" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"></rect></svg>*/}
            <div className="col-lg-12">
              <h6 className="mb-0">{source.title}</h6>
              <small className="text-body-secondary">{source.publisher}</small>
            </div>
          </a>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default Sources;