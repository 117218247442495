import React from 'react';
import { Navbar, Container, Row, Col, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import SectionBar from './SectionBar';
import SearchBar from './SearchBar';
import { useNavigate } from 'react-router-dom';
import '../../CommonStyling.css';
import { useSigninCheck } from 'reactfire';
import LoggedInAuthHeaderComponent from '../auth/LoggedInAuthHeaderComponent';
import LoggedOutAuthHeaderComponent from '../auth/LoggedOutAuthHeaderComponent';
import { useIsMobile } from './Utils';
import MobileHeaderNavMenu from './MobileHeaderNavMenu';
import StudioButton from './StudioButton';

const Header = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();

    const { status, data: signInCheckResult } = useSigninCheck();

    const AuthComponent = () => {
        if (status === 'loading') {
            return <div />
        }

        if (status === 'success') {
            const { signedIn, user } = signInCheckResult;
            if (signedIn === true) {
                return <LoggedInAuthHeaderComponent user={user} />
            }
            return <LoggedOutAuthHeaderComponent />
        }
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            This is a prototype is under active development.
            Report quality will vary, and some features may not work as expected.
        </Tooltip>
    );

    return (
        <Container className='mb-2 mt-3'>
            <Row className="flex-nowrap justify-content-between align-items-center pb-2">
                <Col xs={4} className="pt-1">
                    {/* {isMobile ? <MobileHeaderNavMenu /> : <SearchBar />} */}
                </Col>
                <Col xs={4} className="text-center h-100 d-flex justify-content-center">
                    <Navbar.Brand className={`${isMobile ? 'mobile-title-size' : 'desktop-title-size'} blog-header-logo text-body-emphasis text-decoration-none use-pointer d-flex align-items-center position-relative`} onClick={() => navigate('/')}>
                        {/* <img src="/logo.svg" alt="GPTimes" style={{ height: "56px", marginRight: "5px" }} /> */}
                        GPTimes
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <Badge bg="secondary" style={{ fontSize: "25%", fontFamily: "sans-serif", margin: "10px" }}>
                                Beta
                            </Badge>
                        </OverlayTrigger>
                    </Navbar.Brand>
                </Col>
                <Col xs={4} className="d-flex justify-content-end align-items-center">
                    {/* <StudioButton mobile={isMobile} /> */}
                    <AuthComponent />
                </Col>
            </Row>
            {/* {isMobile && <SearchBar />} */}
            {/* {!isMobile && <Row> */}
            {false && <Row>
                <Col>
                    <SectionBar />
                </Col>
            </Row>}
        </Container>
    );
}

export default Header;