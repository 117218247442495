import React from 'react';
import { Badge, Stack } from 'react-bootstrap';

const DateDisplay = ({dateString, updated}) => {
    return (
        <Stack className="mb-3 text-muted blog-description flex-shrink-0" direction="horizontal" gap={2}>
            {dateString}
            {updated && <Badge bg="secondary">Updated</Badge>}
        </Stack>
    );
}

export default DateDisplay;
