import { doc, setDoc } from "firebase/firestore";
import UpvoteDownvote from "./UpvoteDownvote";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useEffect, useState } from "react";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import "../../CommonStyling.css";

const commentTexts = [
    "Article contains false information",
    "Article is not coherent",
    "Sources are not relevant to article"
]

const thankYouText = "Thank you for your feedback! You are helping us make GPTimes the best source for news! ❤️";

const FeedbackControlsLoggedIn = ({user, reportId, vote, setVote}) => {
    const [comment, setComment] = useState(-1);
    const firestore = useFirestore();
    const docRef = doc(firestore, 'feedback', user.uid, 'reports', reportId);
    const { status, data } = useFirestoreDocData(docRef);

    useEffect(() => {
        if(vote !== 0) {
            setDoc(docRef, {
                vote: vote
            }, {merge: vote === -1});   //keep pre-existing comment if downvote
        }
    }, [])

    //pull initial vote state on mount
    useEffect(() => {
        if(vote === 0 && data?.vote) {
            setVote(data.vote);
        }
    }, [data]);

    //pull initial comment on mount
    useEffect(() => {
        if(data?.comment !== undefined) {
            setComment(data.comment);
        }
    }, [data]);
    
    const onUpvote = async () => {
        //NB: this overwrites doc. if comment previously existed it is deleted
        if(vote === 1) return;
        await setDoc(docRef, {
            vote: 1
        });
        setVote(1);
        setComment(-1);
    }

    const onDownvote = async () => {
        if(vote === -1) return;
        await setDoc(docRef, {
            vote: -1
        }, {merge: true});
        setVote(-1);
    }

    const onSelectComment = async (i) => {
        if(comment === i) return;
        await setDoc(docRef, {
            comment: i
        }, {merge: true});
        setComment(i);
    }
    
    return(
        <div className="p-4 mb-3 bg-body-tertiary rounded">
            <h5 className="text-white">How did we do?</h5>
        {/* <div>
            How did we do? */}
            <UpvoteDownvote vote={vote} onUpvote={onUpvote} onDownvote={onDownvote}/>
            <div className="mb-2"/>
            {vote === 1 && thankYouText}
            {vote === -1 && <div>
                {comment === -1 ?
                    "Please tell us more about what went wrong:" :
                    thankYouText
                }
                <ListGroup className="mt-2">
                    {commentTexts.map((commentText, index) => (
                        <ListGroup.Item 
                            key={index} 
                            active={index === comment} 
                            onClick={() => onSelectComment(index)}
                            className="use-pointer"
                        >
                            {commentText}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>}
        </div>
    )
}

export default FeedbackControlsLoggedIn;