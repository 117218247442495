import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Front from './pages/Front';
import Report from './pages/Report';
import Studio from './pages/Studio';
import Privacy from './pages/Privacy';
import Login from './pages/Login';
import Upgrade from './pages/Upgrade';
import NotFound from './pages/NotFound';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import RequireAuth from './components/auth/RequireAuth';
import RequireTokens from './components/auth/RequireTokens';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { AuthProvider, FirestoreProvider, FunctionsProvider, useFirebaseApp, useFunctions } from 'reactfire';
import { Container } from 'react-bootstrap';
import SearchResults from './pages/SearchResults';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

function App() {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const functions = getFunctions(firebaseApp);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant'
    });
  }, [location.pathname]);

  const firestoreInstance = getFirestore(useFirebaseApp());
  if (window.location.port === '5002') {
    connectFirestoreEmulator(firestoreInstance, 'localhost', 8080);
    // const functionsInstance = getFunctions(useFirebaseApp());
    // connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
  }
  return (
    <Container>
      <AuthProvider sdk={auth}>
        <FunctionsProvider sdk={functions}>
          <FirestoreProvider sdk={firestoreInstance}>
            <Container>
              <Header />
              <Routes>
                <Route exact path="/" element={<Front />} />
                <Route path="/login" element={<Login />} />
                <Route path="/upgrade" element={<Upgrade />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/search/:search" element={<SearchResults />} />
                <Route path="/report/:id" element={<Report />} />
                <Route exact path="/studio" element={<RequireAuth><RequireTokens><Studio /></RequireTokens></RequireAuth>} />
                <Route path="/studio/:id" element={<RequireAuth><RequireTokens><Studio /></RequireTokens></RequireAuth>} />
                <Route path="/:section/:id" element={<Report />} />
                <Route path="/:id" element={<Home />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Footer />
            </Container>
          </FirestoreProvider>
        </FunctionsProvider>
      </AuthProvider>
    </Container>
  );
}

export default App;