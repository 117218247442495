import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Button, Dropdown, Image, Nav } from 'react-bootstrap';
import { useAuth, useSigninCheck } from 'reactfire';
import GoogleLogoSVG from './../../assets/Google__G__Logo.svg';
import AuthButton from './AuthButton';

const GoogleAuthButton = () => {
    const auth = useAuth();

    const signInWithGoogle = async (auth) => {
        const provider = new GoogleAuthProvider();
        var res = await signInWithPopup(auth, provider);
    };

    return <AuthButton logo={GoogleLogoSVG} name="Google" onClick={() => signInWithGoogle(auth)}/>
}

export default GoogleAuthButton;