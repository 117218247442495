import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

//hook to determine if screen is mobile size
export const useIsMobile = () => useMediaQuery({ query: '(max-width: 767px)' });

//hook to determine if the reference is on screen
export default function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])
  
  
    useEffect(() => {
      observer.observe(ref.current)
      return () => observer.disconnect()
    }, [])
  
    return isIntersecting
}