
function AboutCard() {
    return (
        <div className="p-4 mb-3 bg-body-tertiary rounded">
            <h4 className="fst-italic">Our AI Principles</h4>
            <p className="mb-0">⚖️ Conduct Unbiased Analysis</p>
            <p className="mb-0">🔏 Cite Trustworthy Sources</p>
            <p className="mb-0">🤝 Consider Various Perspectives</p>
        </div>
    )
}

export default AboutCard;