import { Dropdown } from "react-bootstrap";

const AuthButton = ({onClick, logo, name}) => {
    return (
        <Dropdown.Item className='align-items-center d-flex flex-row' onClick={onClick}>
            <div className="d-flex justify-content-center w-2" style={{width: "2em"}}>
                <img src={logo} height={26}/>
            </div>
            <span style={{textAlign: "left", marginLeft: "0.5em", display: "inline-block"}}>Sign in with {name}</span>
        </Dropdown.Item> 
    )
}

export default AuthButton;