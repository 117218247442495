import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Stack, Form, InputGroup, Button, Card } from "react-bootstrap";
import ChatCard from './ChatCard';
import { useAuth, useFirestore, useFunctions, useFirestoreDocData, useFirestoreCollectionData } from 'reactfire';
import { doc, collection, query, orderBy, addDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


function WorkstreamConfig({ workstream_id, workstream, messages, workstreamLaunching, setWorkstreamLaunching}) {
    // get the user id from the auth object
    const auth = useAuth();
    const firestore = useFirestore();
    const functions = useFunctions();
    const user_id = auth.currentUser.uid;

    const navigate = useNavigate();

    const [inputValue, setInputValue] = useState('');
    const [sending, setSending] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleLaunch = async () => {
        setSending(true);
        setWorkstreamLaunching(true);
        console.log('sending message:', inputValue);
        const sendMessage = httpsCallable(functions, 'add_message');
        navigate(`/studio/${workstream_id}`);
        await sendMessage({ workstream_id, content: workstream.content });
        setSending(false);
        setWorkstreamLaunching(false);
        setInputValue('');
    }

    const handleRegenerate = async () => {
        console.log('this should regenerate the workstream for', inputValue);
        setSending(true);
        // console.log('sending message:', inputValue);
        const regnerate = httpsCallable(functions, 'regenerateWorkstream');
        await regnerate({ workstream_id, prompt: inputValue });
        setSending(false);
        setInputValue('');
    }

    const handleViewChange = () => {
        navigate(`/studio/${workstream_id}`);
    }

    const handleSaveTemplate = async () => {
        // create new template with current workstream conent]
        // write to firestore 
        const newTemplate = {
            content: workstream.content,
            priority: 0,
            title: workstream.content.split('\n')[0],
            user_id: user_id,
        }

        await addDoc(collection(firestore, 'templates'), newTemplate);
        
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            handleRegenerate();
        }
    };

    return (
        <Container style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: 'relative',
        }}>

            <Container style={{
                height: "100%",
                overflow: "auto",
            }}>
                <Stack direction='vertical' gap={2} style={{maxHeight:"100px"}}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{workstream.content}</ReactMarkdown>
                    <ChatCard hidden message={{content: 'You shouldnt see this', created: new Date()}} sent={true}/>
                </Stack>
            </Container>
            <Card style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
            }}>
                <InputGroup>
                    <InputGroup.Text>
                        ✏️
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Refine worstream..."
                        value={inputValue}
                        disabled={sending}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    {
                        (inputValue === '' 
                            ?<Button 
                                variant={'success'}
                                onClick={handleLaunch}
                                >
                                    Launch 🚀
                            </Button>
                            :<Button 
                                variant={'outline-primary'}
                                onClick={handleRegenerate}
                                disabled={sending || inputValue.trim() === ''}
                                > 
                                    🔄 
                                </Button>

                        )
                    }
                </InputGroup>
            </Card>
            <Card style={{
                position: 'absolute',
                top: '0',
                left: '0',
            }}>
                {/* <Button variant='outline' onClick={handleSaveTemplate}>
                    💾
                </Button> */}
            </Card>
            <Card style={{
                position: 'absolute',
                top: '0',
                right: '0',
            }}>
                <Button variant='outline' onClick={handleViewChange}>
                    💬
                </Button>
            </Card>
        </Container>
    );
}

export default WorkstreamConfig;
