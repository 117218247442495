import { Button } from "react-bootstrap";
import { useIsMobile } from "../common/Utils";

const UpvoteDownvote = ({vote, onUpvote, onDownvote}) => {
    const isMobile = useIsMobile();

    return (
        // <div>
        //     <Button variant={vote === 1 ? "primary" : "outline-primary"} onClick={onUpvote}>👍</Button>
        //     <Button variant={vote === -1 ? "danger" : "outline-danger"} onClick={onDownvote}>👎</Button>
        // </div>
        <div>
            <div className={`btn-group ${isMobile ? 'w-100' : 'w-50'}`}>
                <Button variant={vote === 1 ? "primary" : "outline-primary"} onClick={onUpvote}>👍</Button>
                <Button variant={vote === -1 ? "danger" : "outline-danger"} onClick={onDownvote}>👎</Button>
            </div>
        </div>
        
    ) 
}

export default UpvoteDownvote;