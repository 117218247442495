import { Dropdown, DropdownButton } from "react-bootstrap";
import GoogleAuthButton from "./GoogleAuthButton";
import { useIsMobile } from "../common/Utils";
import { Person } from "react-bootstrap-icons";
import React from "react";
import AppleAuthButton from "./AppleAuthButton";

const MobileDropdownToggle = React.forwardRef(({children, onClick}, ref) => (
    <Person 
        ref={ref} 
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const AuthButtons = [
    <GoogleAuthButton key={0}/>,
    <AppleAuthButton key={1}/>
];

const LoggedOutAuthHeaderComponent = () => {
    const isMobile = useIsMobile();

    if(isMobile) {
        return (
            <Dropdown>
                <Dropdown.Toggle as={MobileDropdownToggle}/>

                <Dropdown.Menu>
                    {AuthButtons}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <DropdownButton align={"end"} id="dropdown-basic-button" title="Sign in">
            {AuthButtons}
        </DropdownButton>
    );
}

export default LoggedOutAuthHeaderComponent;