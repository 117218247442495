import { Alert } from 'react-bootstrap';
import { useState } from 'react';

const Warning = ({ message }) => {
    const [show, setShow] = useState(true);
    return (
        <Alert variant="secondary" show={show} onClose={() => setShow(false)} dismissible>
            {message}
        </Alert>
    );
}

export default Warning;