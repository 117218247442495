import React from 'react';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    
    const navigateTo = (path) => () => {
        navigate(path);
    };

    return (
        <Navbar>
            <Container className='m-2'>
                <Row className="justify-content-md-center" style={{ width: '100%' }}>
                    <Col xs={12} md={4} className="text-center">
                        © {new Date().getFullYear()} GPTimes
                    </Col>
                    <Col xs={12} md={4} className="text-center">
                        <div style={{ cursor: 'pointer' }} onClick={navigateTo('/privacy')}>Privacy Policy</div>
                    </Col>
                    <Col xs={12} md={4} className="text-center">
                        <a style={{ textDecoration: "none", color: "inherit" }} href="mailto:contact@gptimes.ai">Contact Us</a>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
};

export default Footer;