import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { collection, query, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useAuth } from 'reactfire';
import { useParams } from 'react-router-dom';
import AboutCard from '../components/studio/AboutCard';
import Workstreams from '../components/studio/Workstreams';
import Chat from '../components/studio/Chat';
import ChatPlaceholder from '../components/studio/ChatPlaceholder';
import Workstream from '../components/studio/Workstream';


function Studio() {
    // get workstream id and view from the url
    let { id } = useParams(); //this is workstream id
    const firestore = useFirestore();
    
    const auth = useAuth();
    const user = auth.currentUser;
    const uid = user.uid;

    // Get workstreams for this user (workstreams are a subcollection of users)
    const workstreamsCollection = collection(firestore, 'users', uid, 'workstreams');
    const workstreamsQuery = query(workstreamsCollection, orderBy('created', 'desc'));
    const { status, data: workstreams } = useFirestoreCollectionData(workstreamsQuery, {
        idField: 'id',
    });

    if (status === 'loading') {
        return (
            <Container>
                <h1>Loading...</h1>
            </Container>
        )
    }

    
    return (
        <Container style={{height: "calc(100vh - 230px)"}}>
                <Row style={{height:"100%"}}>
                    <Col md={4} className="position-sticky" style={{ top: "2rem" }}>
                        <Workstreams workstreams={workstreams}/>
                    </Col>
                    {id ? <Workstream workstream_id={id} /> : <ChatPlaceholder heading={"Create with AI"} subheading={"Select or create a workstream to get started."}/>}
                </Row>
        </Container>
    );
}

export default Studio;