import EventJumbotron from "../components/home/Jumbotron";
import { Container } from "react-bootstrap";

const NotFound = () => {
    return (
        <Container>
            <EventJumbotron event={{ title: '404', description: 'Page not found :(' }} />
        </Container>
    );
};

export default NotFound;