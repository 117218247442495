import React, { useState, useEffect } from 'react';
import { Container, Col } from "react-bootstrap";
import { useSearchParams } from 'react-router-dom';
import ChatCard from './ChatCard';
import { useAuth, useFirestore, useFunctions, useFirestoreDocData, useFirestoreCollectionData } from 'reactfire';
import { doc, collection, query, orderBy } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import WorkstreamConfig from './WorkstreamConfig';
import Chat from './Chat';

function Workstream({ workstream_id }) {
    // get the user id from the auth object
    const auth = useAuth();
    const firestore = useFirestore();
    const functions = useFunctions();
    const user_id = auth.currentUser.uid;
    const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get("view")

    const [inputValue, setInputValue] = useState('');
    const [workstreamLaunching, setWorkstreamLaunching] = useState(false);

    const workstreamDoc = doc(firestore, 'users', user_id, 'workstreams', workstream_id);
    const { status: docStatus, data: workstream } = useFirestoreDocData(workstreamDoc);
    const messagesCollection = collection(firestore, 'users', user_id, 'workstreams', workstream_id, 'messages');
    const messagesQuery = query(messagesCollection, orderBy('created', 'asc'));
    const { status: collectionStatus, data: messages } = useFirestoreCollectionData(messagesQuery, {
        idField: 'id',
    });

    if (docStatus === 'error' || collectionStatus === 'error') {
        return (
            <Container>
                <h1>Error!</h1>
            </Container>
        )
    }
    if (docStatus === 'loading' || collectionStatus === 'loading') {
        return (
            <Container>
                <h1>Loading...</h1>
            </Container>
        )
    }

    return (
        <Col md={8}>
            {view === 'config' 
            ? <WorkstreamConfig workstream_id={workstream_id} workstream={workstream} messages={messages} workstreamLaunching={workstreamLaunching} setWorkstreamLaunching={setWorkstreamLaunching} /> 
            : <Chat workstream_id={workstream_id} workstream={workstream} messages={messages} workstreamLaunching={workstreamLaunching} setWorkstreamLaunching={setWorkstreamLaunching} />
            }
        </Col>
    );
}

export default Workstream;
