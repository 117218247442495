import React from 'react';
import { Container } from 'react-bootstrap';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Loading from '../components/home/Loading';
import { collection, where, query } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import CardList from '../components/common/CardList';

const SearchResults = () => {
  let { search } = useParams();
  const firestore = useFirestore();
  const eventsCollection = collection(firestore, 'reports');

  // const searchQuery = query(eventsCollection, where('title', '>=', search), where('title', '<=', search + '\uf8ff'))
  // keyword search
  const searchClean = search.trim().toLowerCase().replace(/ /g, "-");
  const searchQuery = query(eventsCollection, where('tags', 'array-contains', searchClean))
  const { status, data } = useFirestoreCollectionData(searchQuery)

  if (status === 'loading') {
    return <Loading />;
  }
  
  return (
    <Container>
      <h3 className="mb-3 text-break text-white flex-shrink-0 mx-1">{data.length} Result{data.length !== 1 && 's'}</h3>
      <CardList data={data} />
    </Container>
  );
}

export default SearchResults;