import React from 'react';
import Card from 'react-bootstrap/Card';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const ChatCard = ({ message, sent, hidden = false }) => {
    // switch role to determine bg color
        let bgColor;
        switch (message.role) {
            case 'user':
                bgColor = 'bg-primary text-white';
                break;
            case 'assistant':
                bgColor = 'bg-dark';
                break;
            case 'proxy':
                bgColor = 'bg-secondary text-white';
                break;
            default:
                bgColor = 'bg-light';
                break;
        }

        let cardTitle;
        switch (message.name) {
            case 'user':
                cardTitle = 'Human';
                break;
            case 'proxy':
                cardTitle = 'Proxy';
                break;
            default:
                cardTitle = message.name;
                break;
        }

        return (
            <Card className={bgColor} style={{ visibility: hidden ? 'hidden' : 'visible' }}>
                <Card.Body>
                    <Card.Title>
                        {cardTitle}
                    </Card.Title>
                    <Card.Text>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {message.content}
                        </ReactMarkdown>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    };

    export default ChatCard;
    