import { Container, Col } from "react-bootstrap";

const ChatPlaceholder = ({heading, subheading}) => {
    return (
        <Col md={8}>
            <Container style={{
                height: "100%", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center", 
                flexDirection: "column",
                opacity: 0.5
            }}>
                <img src="logo.svg" alt="GPTimes Logo" style={{width: "128px", height: "128px"}}/>
                <h2>{heading}</h2>
                <p>{subheading}</p>
            </Container>
        </Col>
    );
    }

export default ChatPlaceholder;