import './SideMenu.css';
import { Card, CloseButton } from "react-bootstrap";
import { useEffect } from "react";
import Close from "./Close";
import { useIsMobile } from './Utils';
import { ChevronRight } from 'react-bootstrap-icons';


const SideMenu = ({children, onClose, position = 'left'}) => {
    //prevent scroll when open
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
    }, []);

    const isMobile = useIsMobile();

    return (
        <div onClick={onClose} className="modal-fade">
            <Card onClick={(e) => e.stopPropagation()} className={`side-menu ${isMobile && "mobile"} ${position} border-0 rounded-0`}>
                <Close onClick={onClose}/>
                {children}
            </Card>
        </div>
    )
}

export const SideMenuListItem = ({children, onClick}) => {
    return (
        <a className="d-flex justify-content-between align-items-center text-start border-top link-body-emphasis text-decoration-none pt-2 pb-2" onClick={onClick}>
            {children}
            <ChevronRight/>
        </a>
    )
}

export default SideMenu;