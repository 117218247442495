import React, { useState } from "react";
import { doc, increment, updateDoc } from 'firebase/firestore';
import { Button, Card } from "react-bootstrap";
import { useFirestore, useFirestoreDocData, useSigninCheck } from "reactfire";
import FeedbackControlsLoggedIn from "./FeedbackControlsLoggedIn";
import FeedbackControlsLoggedOut from "./FeedbackControlsLoggedOut";

const FeedbackForm = ({ reportId }) => {
    const [vote, setVote] = useState(0);

    const { status: signinStatus, data: signInCheckResult } = useSigninCheck();

    //make sure signin status is known before interactable
    if(signinStatus === 'loading') {
        return (
            <div>loading...</div>
        )
    }

    const { signedIn, user } = signInCheckResult;

    return (
        <div>
            {
                signedIn === true ? 
                <FeedbackControlsLoggedIn user={user} reportId={reportId} vote={vote} setVote={setVote}/> :
                <FeedbackControlsLoggedOut vote={vote} setVote={setVote}/>
            }
        </div>
    );
}

export default FeedbackForm;