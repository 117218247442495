// wrapper for components that require authentication, if user is not authenticated, redirect to login (useNavigate)

import React from 'react';
import { useSigninCheck } from 'reactfire';
import { useNavigate } from 'react-router-dom';

const RequireAuth = ({children}) => {
    const { status, data: signInCheckResult } = useSigninCheck();
    const navigate = useNavigate();
    if (status === 'loading') {
        return <h1>Checking authorization...</h1>;
    }
    if (!signInCheckResult.signedIn) {
        navigate('/login');
        return null;
    }
    return children;
}

export default RequireAuth;
